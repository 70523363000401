<template>
  <Page title="Contact me" >
    <div :class="['contactMe',{'isDesktop': isDesktop}]">
      <div>
        <div class="item">
          <img src="@/assets/location.svg" alt="" class="image" />
          <p>UK, London</p>
        </div>
        <div class="item">
          <img src="@/assets/phone.svg" alt="" class="image" />
          <p><a href="tel:00447902775659">(+44) 07902 775659</a></p>
        </div>
        <div class="item">
          <img src="@/assets/email.svg" alt="" class="image" />
          <p>
            <a href="mailto:lavinia.lattanzio@gmail.com"
              >lavinia.lattanzio@gmail.com</a
            >
          </p>
        </div>
        <div class="item">
          <a href="https://www.linkedin.com/in/lavinia-lattanzio-11a550134/"
            ><img src="@/assets/linkedin.svg" class="image"
          /></a>
          <p></p>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
export default {
  name: "ContactMe",
};
</script>

<style lang="scss" scoped>
.contactMe {
  text-align: left;
  .item {
    display: flex;
    align-items: center;
    margin: 3rem 0;
    .image {
      width: 50px;
    }
    p {
      font-size: 1rem;
      margin-left: 20px;
      a {
        color: #4a4a4a;
      }
    }
  }
}
</style>
