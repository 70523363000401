<template>
  <Page title="Case studies">
    <div class="is-flex block">
      <div>
        <div class="columns">
          <div class="column is-half">
            <div class="card" @click="goTo('SugarFairy')">
              <div class="card-image">
                <figure class="image">
                  <img
                    src="@/assets/cases/sugarFairy/7.jpg"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">Sugar Fairy</p>
                  </div>
                </div>
                <div class="content">
                  A multisensory storytelling app for children with visual
                  impairments.
                </div>
                <button class="button is-primary">Read</button>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="card" @click="goTo('AirFuels')">
              <div class="card-image">
                <figure class="image">
                  <img
                    src="@/assets/cases/airFuels/prototypemockup.jpg"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <p class="title is-4">Air-Fuels</p>
                  </div>
                </div>
                <div class="content">
                  Air Fuels is a concept mobile app for corporate pilots that allows to book in advance fuel deliveries, manage the airplane’s tank status and act as an agenda to their flight schedule.
                </div>
                <button class="button is-primary">Read</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
export default {
  name: "CaseStudies",
  methods: {
    goTo(url) {
      this.$router.push({ name: url });
    },
  },
};
</script>

<style lang="scss" scoped>
.block {
  margin-bottom: 30px;
  text-align: left;
  .card {
    cursor: pointer;
    &:hover {
      box-shadow: 0 0.5em 1em -0.125em rgba(1, 1, 1, 0.3),
        0 0px 0 1px rgba(1, 1, 1, 0.02);
    }
    .image {
      margin: 0;
      img {
        width: 100%;
      }
    }
    .title,
    .content {
      padding: 0 !important;
    }
  }
}
</style>
