<template>
  <Page title="About me" :class="['aboutMe',{'isDesktop': isDesktop}]">
    <div :class="['block',{'is-flex': isDesktop}]">
      <div class="photo">
        <img src="@/assets/aboutme.jpeg" alt="" class="photo" />
      </div>
      <div class="description">
        <h3>I'm Lavinia Lattanzio<br />UI/UX Designer</h3>
        <p>
          Hi! I’m Lavinia and I’m an UX designer. I’m deeply interested
          in cognitive psychology and human-centered design and I believe
          empathy to be key to create useful, usable, and valuable experiences
          for people.<br /><br />
          I’m curious, proactive, eager to learn from others, and always ready to vent my opinions and ideas! I'm very quick to learn and I could be a great contribution thanks to my experience, curiosity, and enthusiasm.
        </p>
        <Social />
      </div>
    </div>
  </Page>
</template>

<script>
import Social from "@/components/Social";
export default {
  name: "AboutMe",
  components: {
    Social,
  },
};
</script>

<style lang="scss" scoped>
.block {
  margin-bottom: 30px;
}
.photo {
  img {
  padding: 12px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
  }
  margin-bottom: 2rem;
}
.description {
  text-align: left;
}
.isDesktop {
  .description {
    padding-left: 2rem;
  }
}
</style>
