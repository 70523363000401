<template>
  <Page title="Case study" :padding="false">
    <div :class="['airFuels',{'isDesktop': isDesktop}]" >
    <div class="block first bg-lightBlue">
      <div :class="[{'is-flex': isDesktop}]">
        <div>
      <h1 class="big blue">Air-Fuels</h1>
      <p>
        <b>Air Fuels is a concept mobile app for
corporate pilots</b> that allows to book in
advance fuel deliveries, manage the
airplane’s tank status and act as an agenda
to their flight schedule.
      </p>
      <p>The app was thought following my experience
with a company where I was requested to
design a workflow that would show their clients
how their software works.</p>
<p>I took inspiration from this experience and
decided to create my version of the app.
The reason? <b>To strengthen and exercise
my UX and UI skills.</b></p>

        </div>
      <img
        src="@/assets/cases/airFuels/mockup.png"
        alt=""
        class="image-centered"
        width="330px"
      />
      </div>
    </div>
    <div class="block bg-blue has-text-centered white">
      <h2 class="big white">The challenge</h2>
      <p>Because of the coronavirus, the greatest challenge
for me had been to <b>create an app without the possibility
to interact and get feedback from actual potential users
(pilots), experts of the industry field, or even similar
target users.</b> The knowledge I had of users and their needs
derived just from the brief experience I had with my clients
and for confidentiality reasons I cannot share any further
details on the matter.</p>
<p>Despite this fact, I managed to create a high-fidelity
prototype that aims to offer users a natural, quick,
and easily understanding experience that seems to
respond to core usability heuristics.</p>
    </div>
    <div class="block bg-lightBlue">
      <h2 class="big blue">The process</h2>
      <h3 class="medium blue">Competitor Analysis</h3>
      <p>To have a better understanding of the context, <b>I executed a competitor analysis to
discover what’s on the market and where the product could potentially fit in</b>. I
focused on small-mid-sized companies with similar targets that offer the possibility to
manage and plan aircraft’s fuel activity from a mobile app. Another aim of the analysis
was <b>to observe and take inspiration for design features</b>.</p>
<img
        src="@/assets/cases/airFuels/competitive.jpg"
        alt=""
        class="image-centered"
        style="padding-top: 40px"
      />
    </div>
    <div class="block">
<img
        src="@/assets/cases/airFuels/diagram.png"
        alt=""
        class="image-centered"
      />
      <div class="userFlow">
      <h3 class="medium blue">User Flow</h3>
      <p>Starting from what I previously created
for my clients, <b>I created a new user
flow, focusing the attention on the
possible core tasks that users could
perform</b> in my fictional app: book or
cancel a fuel delivery, keep track of their
future flight schedule and check
previous invoices.</p>
      </div>
      <div class="planes" :style="{'background-image': 'url(' + require('@/assets/cases/airFuels/plane.png') + ')'}"></div>
    </div>
    <div class="block sketch" :style="{'background-image': 'url(' + require('@/assets/cases/airFuels/prototype.jpg') + ')'}">
      <div class="planes" :style="{'background-image': 'url(' + require('@/assets/cases/airFuels/plane.png') + ')'}"></div>

      <div class="overlay white">
        <h3 class="medium white">Paper sketches and mid-fidelity prototype</h3>
        <p>I realized paper sketches in order to externalize my
thoughts, try different possibilities, and have a traced
path to follow for constructing the prototype.</p>
<p>After that I built a mid-fidelity prototype, using <b>Adobe
XD</b>, focusing again on the core task that a user could
perform.</p>
      </div>
    </div>
    <div class="block bg-lightBlue">
            <div class="planes blue" :style="{'background-image': 'url(' + require('@/assets/cases/airFuels/plane.png') + ')'}"></div>
  <h2 class="blue">Pilot Usability Test</h2>
  <p>Once my mid-fi prototype was ready, <b>I ran a pilot test with just one participant in order
to discover any usability issues before passing to the actual design</b>. The goal of the test
was whether users were able to figure out how to book a fuel delivery (task1), follow the
delivery and refilling procedure from their own device (task 2), and retrieve any past
invoices (task3).</p>
<div :class="{'is-flex': isDesktop}">
  <div class="is-flex" style="max-width: 448px; padding: 1rem 1rem 1rem 0;">
  <img  src="@/assets/cases/airFuels/photo1.jpeg" style="width:50%;">
  <img  src="@/assets/cases/airFuels/photo2.jpeg" style="width:50%;">
  </div>
  <div>
    <p><b>The test was video and audio recorded,
and the participant was asked to
complete 3 different tasks:</b></p>
<ol>
  <li>You are a pilot and you want to book in
advance a fuel delivery at your next
destination.</li>
  <li>As you arrive in New York you want to be
sure that your aircraft is refueled.</li>
  <li>Your company wants you to retrieve past
receipts. Find all e-tickets from Shell.</li>
</ol>
  </div>
</div>
<p>For each task, I compiled a list of subtasks that I checked while the participant tried
to fulfill his goals. After the test, I asked him to rate his subjective satisfaction,
perceived effort or difficulty, and general interface appearance through a Likert scale
(from 1 to 5).</p>
<p><b>From the results, the workflow appeared natural and quick and the success rate
was 92%.</b> Few confusions derived from the prototype itself: the participant wanted to
insert the data in a different order, whether the prototype supported just a linear
inserting of data. In his subjective opinion, he quite enjoyed the experience (4 out of 5
in subject satisfaction) and perceived little effort on his tasks (2 out of 5) and judged
the interface pretty cool, even if there weren't any visual design elements (4 out of 5).</p>
    </div>
    <div class="block keyWords">
            <div class="planes" :style="{'background-image': 'url(' + require('@/assets/cases/airFuels/plane.png') + ')'}"></div>

      <h2 class="blue">Key words and mood board</h2>
      <p>Once I tested the prototype, I identified 3 keywords as representative of the emotions
and feelings I wanted the app to arise to users.</p>
<div  :class="['flex',{'is-flex': isDesktop}]">
  <div class="is-flex is-flex-direction-column">
    <h2 class="blue">TRUST</h2>
    <h2 class="blue">FREEDOM</h2>
    <h2 class="blue">LIGHTNESS</h2>
  </div>
    <img  src="@/assets/cases/airFuels/final.jpg" style="padding: 1rem  1rem 1rem;">
</div>
<p>I used these words to create a mood board and to inspire and guide me while
building visual design.</p>
    </div>
    <div class="block last bg-lightBlue" :style="{'background-image': 'url(' + require('@/assets/cases/airFuels/prototypemockup.jpg') + ')'}">
          <div class="planes" :style="{'background-image': 'url(' + require('@/assets/cases/airFuels/plane.png') + ')'}"></div>

    <div>
      <h2>High-fidelity mockup</h2>
      <p>I then realized mockups
and a hi-fi prototype
demo, taking into
consideration <b>font
weight, size, and colors
to structure the visual
hierarchy to drive the
user's attention to the
main entry points.</b></p>
    </div>
    </div>
    <div class="block bg-blue has-text-centered white">
      <h1 class="white">Heuristic evaluation</h1>
      <p class="has-text-left">After the mockups, I wanted to draw final conclusions about this project. To do this I
decided to entrust UX and UI heuristics, a series of principles and rules of thumb made
by experts to evaluate an interface. In particular, I used <b>Jacob Nielsen and Molich’s 10
user interface guidelines as a testbed.</b></p>
<img
        src="@/assets/cases/airFuels/rules.jpg"
        alt=""
        class="image-centered"
        style="padding-top: 40px"
      />
    </div>
    </div>
  </Page>
</template>
<script>
export default {
  name: "AboutMe",
};
</script>

<style lang="scss" scoped>
$blue: #344267;
  $lightBlue: #e7ebee;
.block {
  margin: 0!important;
  padding: 8rem 2rem;
  position: relative;
  &.first {
    img {
        margin-top: 5rem!important;
      }
  p {
    font-size: 1.3rem;
  }
  }
  text-align: left;

  h1 {
    font-weight: 900 !important;
  }
  h3 {
    font-weight: 900 !important;
  }
  .overlay {
    background-color: rgba($blue,0.8);
    padding: 2rem;
    // widows: 80%;
    // position: absolute;
    margin: 2rem;
    // top: 0;
    // left: 0;
  }
  .image-centered {
    margin: auto;
    display: block;
    max-width: 100%;
  }
  .float-left {
    float: left;
    padding: 0 40px 40px 0;
  }
  .float-right {
    float: right;
    padding: 0 40px 40px 0;
  }
  .image-right {
    padding-left: 40px;
  }
  .big {
    font-weight: 900;
    font-size: 3rem;
  }
  
  
}
.planes {
  position: absolute!important;
  width: 100%!important;
  height: 8px;
  // background-color: rgba(0,0,0,0.5);
  background-size: 16px!important;
      left: 0;
    top: -4px;
    padding: 0!important;
    // background-repeat: ;
}
.white {
    color: $white !important;
  }
  .blue {
    color: $blue !important;
  }
  .bg-blue {
    background-color: $blue !important;
  }
  .lightBlue {
    color: $lightBlue !important;
  }
  .bg-lightBlue {
    background-color: $lightBlue !important;
  }
.banner {
  height: 4rem;
  background-color: $primary;
}

.last {
    div {
      padding: 2rem;
      background: rgba($lightBlue,0.8)
    }
    background-size: cover;
  }
  .keyWords {
     .flex {
      div {
    justify-content: center;
    align-items: center;
    }
    }
  }

.isDesktop {
  .first {
    img {
        margin-top: 0rem!important;
        
      }
      p {
        font-size: 1.4rem;
      }
  }
  .keyWords {
    .flex {
      div {
      width: 30%;
    }
    img {
      width: 70%;
    }
    }
  }
  .last {
    div {
      padding: 0;
      width: 30%;
      background-color: unset;
    }
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: 110%;
  }
  .first {
    .is-flex {
      
      img {
        margin-top: 0;
      }
      div {
        padding-right: 4rem!important;
      }
    } 
  }
  .userFlow {
    margin-top: 0;
  position: absolute;width: 46%; right: 0; top: 44%;
  }
  .sketch {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }
}
.sketch {
  background-size: cover;
  background-position: 50% 50%;}
.userFlow { margin-top: 2rem;
}
ul li::marker ,ol li::marker {
  color: $blue!important;
    font-weight: bold;
}
</style>
