<template>
  <Page title="Case study" >
    <div :class="['sugarFairy',{'isDesktop': isDesktop}]">
      <div class="block first" >
      <h1>Sugar Fairy</h1>
      <p class="subtitle">
        A multisensory storytelling app for children with visual impairments
      </p>
      <p>
        <a href="https://www.researchgate.net/publication/350055038_Multisensorial_tangible_user_interface_for_immersive_storytelling_a_usability_pilot_study_with_a_visually_impaired_child" style="text-decoration:underline">Sugar Fairy</a> is a multisensorial storytelling game that exploits tangible
        user interfaces: smart objects that act as a medium with which children
        can experience a digital story. To play, children will have to choose
        the right object at the right time to unlock one by one the story’s
        scenes. Sugar fairy is inspired by <a href="https://www.researchgate.net/publication/307853896_Digital_and_Multisensory_Storytelling_Narration_with_Smell_Taste_and_Touch" style="text-decoration:underline">MSS</a>, a multisensorial storytelling
        game ideated for children between 6-8. The challenge was to readapt the
        MSS and tailor it for the necessities of a player with visual
        impairments.
      </p>
      <img
        src="@/assets/cases/sugarFairy/7.jpg"
        alt=""
        class="image-centered"
        width="430px"
        style="padding-top: 2rem"
      />
    </div>
    <div class="block">
      <div class="columns">
        <div class="column is-three-fifths">
          <h3>The Context</h3>
          <p>
            <b
              >Storytelling represents an important tool for the development of
              children</b
            >, contributing to the strengthening of cognitive, emotional,
            linguistic, and social skills. Storytelling is also a powerful tool
            used in special education practices with children with visual
            impairments as it allows them to strengthen their ability to build
            mental images and access symbolic representations.
            <b
              >With the development of new technologies, also our way to tell
              stories evolved, relaying for the majority on screens and
              exploiting the visual channel.</b
            >
          </p>
          <p>
            Grounded onto reality and relying on the haptic senses,<b>
              tangible user interfaces (TUI) could represent a valid ally for
              children with visual impairments.</b
            >
            Despite this fact, very few products and apps that exploit TUI have
            been realized on this matter, especially for storytelling.
          </p>
          <p>
            <b>Born as my thesis project</b>, In collaboration with the University of Roma Tre, the Institute of Science and Technology of Cognition of CNR (Centre of National Research), Smarted srl, and with the University of Naples Federico II, I joined the
            <b
              >creation, implementation, and testing of “Sugar Fairy”, a
              multisensory storytelling app tailored to satisfy the needs of a
              6-8 years old child with visual impairments.</b
            >
          </p>
        </div>
        <div class="column is-two-fifths">
          <img src="@/assets/cases/sugarFairy/10.jpg" class="image-right" style="padding-left: 0"/>
        </div>
      </div>
      <img src="@/assets/cases/sugarFairy/8.jpg" />
    </div>
    <div class="block">
      <h3>The challenge</h3>
      <img src="@/assets/cases/sugarFairy/2.png" alt="" class="float-left" />
      <p>
        <b
          >The challenge was to readapt an existing system, the <a href="https://www.researchgate.net/publication/307853896_Digital_and_Multisensory_Storytelling_Narration_with_Smell_Taste_and_Touch" style="text-decoration:underline">MSS</a> , to the
          necessities of a child with visual impairments.
        </b>
      </p>
      <p>As Sugar Fairy, MSS consists of a kit comprehending:</p>
      <ul>
        <li>
          <b>Smart objects</b> (objects tagged with an RFID tag): jars with
          smells, candy containers, and some puppets.
        </li>
        <li>
          <b>Hardware:</b>the Magic Board (a plastic board containing an RFID
          reader and antenna) connected to a computer or tablet
        </li>
        <li>
          <b>Software:</b> developed through the STELT platform, installed in
          the computer/tablet.
        </li>
      </ul>
      <p>
        <b
          >To play, the child needs to listen to the story and answer the
          system’s requests </b
        >(reproduced via audio and video from the tablet)
        <b
          >by selecting one of the smart objects at hand and putting it on the
          magic board.</b
        >
        If the answer is correct (if the right object is selected) the story
        goes on with other requests, if the answer is wrong, the child is
        invited to try again until he gets the right object.
      </p>
      <p>
        Thanks to the exploitation of the smart objects, which imply
        manipulation, and to the possibility to include in the activity other
        unusual senses for storytelling practices, such as smell and taste, the
        system seems to be appropriate to the necessities of children with
        visual impairments. On the contrary,<b>
          since it was built for 6-8 years old typically developing children,
          the plot of the story and the robotic audio reproduced by the speaker
          of the tablet, do not seem to respond to their needs.</b
        >
      </p>
    </div>
    <div class="block">
      <h3>The Process</h3>
      <p>
        To take forward our project we adopted a design thinking approach which
        includes 5 phases:
      </p>
      <img
        src="@/assets/cases/sugarFairy/6.png"
        alt=""
        class="image-centered"
        style="width: 600px; padding-top: 40px"
      />
      <!-- <img src="@/assets/cases/sugarFairy/2.png" alt="" class="float-left"> -->

      <!-- <div class="is-flex">
          <img  src="@/assets/cases/sugarFairy/3.png" >
          <img  src="@/assets/cases/sugarFairy/4.png" >
        </div> -->
    </div>
    <div class="block">
      <h3>Empathize</h3>
      <p>
        After some in-depth desk research on the matter, I and my team run a
        contextual interview with a specialist in the field in order to discover
        more and gain precious advice about how children with visual impairments
        approach storytelling. We discovered that:
      </p>
      <ul>
        <li>
          Tactile books and story boxes are the most common tools to experience
          a story and they play an important role within educational practices,
          as they represent a medium to learn about the world, different
          contexts, and situations.
        </li>
        <li>
          In specific, tactile books have a high cost and are difficult to
          source and time-consuming to build.
        </li>
        <li>
          Children at an early age need to process one new stimulus at a time
          before being exposed to another, so they don’t feel lost and lose
          their motivation to continue the task.
        </li>
        <li>
          The presence of a teacher appears to be essential to guide young
          children through the storytelling activity.
        </li>
      </ul>
    </div>
    <div class="block">
      <h3>Define</h3>
      <p>
        Thanks to the insights gained in the empathize phase, we defined
        meaningful and actionable problem statements to drive our design
        process. To word our reasoning using a Madlib POV approach, we agreed
        that:
      </p>
      <div class="columns">
        <div class="column is-three-fifth">
          <p>
            <b
              >Our user was a 6-8 years old child with visual impairments who
              needs to:</b
            >
          </p>
          <ul>
            <li>
              use his residual senses to experience a story so he can build up
              in his mind an appropriate mental representation of the plot,
              characters, situations, and other shades of the story.
            </li>
            <li>
              be able to continue his educational practices also in a context
              different to the scholastic one, such as at home, and even without
              the presence of a teacher, so that he could increase his degree of
              autonomy and self-efficiency.
            </li>
          </ul>
          <p><b>Our user was a special educator who needs to:</b></p>
          <ul>
            <li>
              support and effectively engage children with visual impairments
              during educational activities since they could quickly lose
              motivation and concentration.
            </li>
            <li>
              Share his/her attention on different children and keep track of
              the problems and progress of each to build an appropriate
              educational plan.
            </li>
          </ul>
        </div>
        <div class="column is-two-fifth">
          <img src="@/assets/cases/sugarFairy/11.jpg" class="image-right" />
        </div>
      </div>

      <p>
        <b
          >Once we had a better understanding of our users, our project was
          driven by the aim of answering these questions:</b
        >
      </p>
      <p class="big">
        <span class="primary"
          >How might we exploit the residual senses of a child with visual
          impairment to help him build an appropriate mental representation of a
          story? </span
        >How might we help him acquire a higher degree of autonomy and
        self-efficiency in storytelling practices?
        <span class="primary"
          >How might we help special educators to support and engage effectively
          their students? </span
        >How might we help them share their attention and track the problems and
        progress of each child?
      </p>
    </div>
    <div class="block">
      <h3>Ideate</h3>
      <p>
        MSS and its architecture seem to answer well to these questions, as they
        rely on tangible interfaces and an intelligent and adaptive tutoring
        system.
        <b>
          The idea was to build another storytelling game by using the STELT
          platform</b
        >, the same used to code MSS.
        <b>Thanks to STELT is indeed possible to create a system that:</b>
      </p>
      <div class="columns">
        <div class="column is-three-fifth">
          <ul>
            <li>
              <b>supports a ludic storytelling activity</b> where the child is
              directly involved and engaged in the active construction of the
              story.
            </li>
            <li>
              <b>can track the children’s performance</b>, such as errors and
              timing, and can <b>respond with the appropriate feedback</b> to the
              user’s actions.
            </li>
            <li>
              <b>can be reproduced in formal and informal contexts </b>such as
              at home and in complete autonomy.
            </li>
          </ul>
        </div>
        <div class="column is-two-fifth">
          <img src="@/assets/cases/sugarFairy/12.jpg" class="image-right" />
        </div>
      </div>

      <p>
        To create an educational storytelling game that would suit the needs of
        a blind and visually impaired player
        <b>we established some functional goals to drive our design process:</b>
      </p>
      <ul>
        <li>
          <b>Build a story appropriate to a special educational context </b>by
          taking inspiration from stories in tactile books.
        </li>
        <li>
          <b
            >Create an app that aims to satisfy both an entertainment and
            educational goal.</b
          >
          While the educational goal is implicit in the storytelling activity
          per se, the game goal would be to <b>“solve and build”</b>: find the
          right object and actively construct the story.
        </li>
        <li>
          <b
            >Pay attention to the quality of the haptic, audio, smell, and taste
            materials </b
          >(the smart objects) to give the child the possibility to experience
          and build a vivid mental representation of the situations at hand.
        </li>
      </ul>
    </div>
    <div class="block">
      <h3>Prototype</h3>
      <p>
        As MSS, "Sugar Fairy" comprehends a kit of smart objects, a magic board,
        and a computer with installed the game.
        <b>
          To realize the “Sugar Fairy” app we followed a special methodology for
          the design of educational games: the situated psychological agent
          model.</b
        >
        According to this, the interactions between a user and the system can be
        seen as two agents interacting with each other: an
        <b>On-Stage Agent, the child,</b> that needs to listen to the story and
        select the right object; and a
        <b>Back-Stage Agent, the artificial tutoring system</b>, that reproduces
        the story and make requests. The interaction between the OSA and BSA is
        presented in the schema below:
      </p>
      <img
        src="@/assets/cases/sugarFairy/5.png"
        alt=""
        class="image-centered"
        style="padding: 3rem"
      />
      <p>
        To build the game we planned and respected some steps to produce a
        <b>high fidelity prototype:</b>
      </p>
      <ol>
        <li>
          <b class="primary">Writing of the story:</b> <b>the story was written by me</b> and inspired by
          “Giorgetto, l’animale che cambia aspetto (the animal that changes
          skin), a tactile book focused on recognition of different textures.
          The story is about a lizard named Smilzon that is not happy with his
          skin and asks Sugar Fairy if she can help him reach his dream to have
          a skin soft and white as a sheep’s (won’t spoiler the end).
        </li>
        <li>
          <b class="primary">Recording of audio and image sourcing:</b> instead of robotic audio that
          narrates the story and asks children requests like it was in MSS,<b> I
          thought of involving professional actors to record both the narration
          of the story and the various requests</b>. After that, files audio with
          background music and sound effects were created. To support the
          usability also for sighted people we included images on the screen
          along together with the audio.
        </li>
        <li>
          <b class="primary">Interactions architecture and implementation on the STELT platform:</b> <b>I first established the architecture of
the various possible interaction between the OSA and BSA:</b> the story was structured in 10 scenes and, for each of
those, the system reproduced an image of background, an audio (e.g. “Sugar Fairy lived at the center of the wood..”),
and a request (e.g. “Find the smell of apple and put it on the magic board”). After that, my colleague Federica Somma,
from the University of Naples, developed the app by using the STELT platform.
        </li>
        <li>
          <b class="primary">Finding and building smart objects:</b> these comprehended a kit with 1
          barbie (the sugar fairy), one lizard, one lizard with feather skin,
          one with synthetic grass skin, and one with wool skin. Also, there
          were 3 smell jars (soap, apple, and burnt), two candy containers
          (cherry and orange), and the magic board (provided from the CNR), and
          a bracelet. <b>Each item was then attached to an RFID tag and registered
          in the system with a special unique code</b>, which allows the system to
          recognize the objects when positioned on the magic board.
        </li>
      </ol>
      <img
        src="@/assets/cases/sugarFairy/7.jpg"
        alt=""
        class="image-centered"
        style="margin-top: 40px;"
        width="430px"
      /> 
    </div>
    <div class="block">
      <h3>Test</h3>
      <p>In march 2020 the prototype was built, and we were ready to test it! But then…</p>
      <h4 style="text-align: center;">CORONAVIRUS HAPPENED</h4>
      <p>Testing the system on children with visual impairments became a very hard goal to reach and we had to adapt to the new situation. We choose to run a pilot test with typically developing children to test the usability and engagement of the system.</p>
      <p>We managed to meet in total security with 11 children (2 boys and 9 girls) aged between 5 and 9 years old. Each session took place at the respective children’s homes and was structured in:</p>
      <ol>
        <li><b class="primary">Sanitizing of objects.</b></li>
        <li><b class="primary">Parent’s informed consent form signed</b></li>
        <li><b class="primary">Questionnaire for parents </b>regarding the child’s usage and experience with technology and tangible user interface.</li>
        <li><b class="primary">Pre-training phase</b> on the use of TUI using MagicBlock, another app developed with STELT based on plastic blocks that differed in colors, form, and size.</li>
        <li><b class="primary">Testing-phase: </b>The children were invited to explore the objects and, when ready, he was asked to position his bracelet on the magic board to start the game. While playing, we took notes of our observation, compiled a checklist created for the present study and </li>
        <li><b class="primary">Questionnaire for children</b> regarding the usability, acceptance, and engagement of the system. Also, we decided to include 4 questions related to the story: 2 context questions (what happened in the story) and 2 action questions (related to the interaction the child had with the story).</li>
      </ol>
    </div>
    <div class="block">
      <h3>Results</h3>
      <p> Our results were based on the analysis of observational notes and checklists, audio recordings of the sessions, and questionnaires. In conclusion, the most important consideration/ we evidenced that:  </p>
      <ul>
        <li>The system showed an <b>overall good level of attention, acceptance, and engagement,</b> with differences according to age. </li>
        <li><b>Feedback of positive response should be included</b> in case of correct answer before reproducing the multimedia of
the following scene. In the current prototype, the system provides just negative feedback and the correct answer is
signalled by the continuing of the story.</li>
        <li>The majority of <b>children seemed annoyed by the use of the bracelet </b>used to start the game, but it could also depend on the materials with which the object was crafted (tape, paper, and a man-size watch strap).</li>
        <li>Fully immersed in a technological world,<b> our sighted participants expected to see something happening on the
screen while playing</b>. Instead, our system, since it was designed for blind and visually impaired children, displayed
static images placed on the screen as background support.</li>
      </ul>
      <p>For more information you can read the article: <a style="text-decoration: underline" href="https://www.researchgate.net/publication/346195564_Usability_and_engagement_of_a_digital_and_multisensorial_tool_for_immersive_storytelling_a_pilot_study" target="_blank">Usability and engagement of a digital and multisensorial tool for
immersive storytelling: a pilot study</a>
</p>
      </div>
      <div class="block">
        <h3>THE END ?</h3>
        <p>Finally, a few months later my colleague Federica had the chance to test our game with a child with visual impairments. You can read about it in detail <a href="https://www.researchgate.net/publication/350055038_Multisensorial_tangible_user_interface_for_immersive_storytelling_a_usability_pilot_study_with_a_visually_impaired_child" style="text-decoration: underline" target="_blank">Multisensorial tangible user interface for immersive storytelling: a usability
pilot study with a visually impaired child.</a></p>
      <p>Today, almost a year later, the University of Roma Tre, The University Federico II of Naples, Smarted srl, the Institute of Science and Technology of Cognition of CNR (Centre of National Research), in collaboration with the Regional Centre Sant’Alessio are now <b>planning to follow up the testing with the app to discover further insight and possibilities that this app and this tool could reserve to blind and visually impaired children.</b></p>
      </div>
    </div>
    
      <div class="banner"></div>
  </Page>
</template>

<script>
export default {
  name: "AboutMe",
};
</script>

<style lang="scss" scoped>
.first {
  margin-top: 2rem;
}
.isDesktop {
  .block {

  padding: 0;
  }
}
.block {
  padding: 0rem 2rem;
  .columns {
    margin-left: 0;
    margin-right: 0;
  }
  margin-bottom: 6rem !important;
  text-align: left;
  .subtitle {
    font-weight: 700 !important;
    margin-bottom: 3rem !important;
    text-align: center;
  }
  h1 {
    text-align: center;
    color: $primary !important;
    font-weight: 900 !important;
  }
  h3 {
    color: $primary !important;
    font-weight: 900 !important;
  }
  .image-centered {
    margin: auto;
    display: block;
    max-width: 100%;
  }
  .float-left {
    float: left;
    padding: 0 40px 40px 0;
  }
  .float-right {
    float: right;
    padding: 0 40px 40px 0;
  }
  .image-right {
    padding-left: 40px;
  }
  .big {
    font-weight: 900;
    font-size: 1.2rem;
  }
  .primary {
    color: $primary !important;
  }
}
.banner {
  height: 4rem;
  background-color: $primary;
}
</style>
