<template>
  <Page title="My Resume">
    <div class="is-flex block">
      <div>
        <div class="columns">
          <div class="column is-half">
            <div class="main">
              <h3 class="subtitle">Education</h3>
              <img src="@/assets/divider.svg" alt="" class="svg" />
            </div>
            <div class="timeline">
              <div
                class="timeline-item"
                v-for="(item, index) in education"
                :key="index"
              >
                <div
                  :class="[
                    'timeline-marker',
                    item.image ? 'is-image is-32x32' : '',
                  ]"
                >
                  <img
                    v-if="item.image && item.image === '@/assets/logo-idf.svg'"
                    src="@/assets/logo-idf.svg"
                  />
                  <img
                    v-if="
                      item.image && item.image === '@/assets/logo-roma3.png'
                    "
                    src="@/assets/logo-roma3.png"
                  />
                </div>
                <div class="timeline-content">
                  <p class="heading" v-if="item.date">
                    <span>{{ item.date }}</span>
                  </p>
                  <p v-if="item.name" class="name">{{ item.name }}</p>
                  <p v-if="item.place" class="place">{{ item.place }}</p>
                  <p v-if="item.location" class="location">{{ item.location }}</p>
                  <p v-if="item.description" class="description">
                    {{ item.description }}
                  </p>
                  <p class="link" v-if="item.link">
                    <a :href="item.link" target="_blank">Link</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-half">
            <div class="main">
              <h3 class="subtitle">Experience</h3>
              <img src="@/assets/divider.svg" alt="" class="svg" />
            </div>
            <div class="timeline">
              <div
                class="timeline-item"
                v-for="(item, index) in experience"
                :key="index"
              >
                <div
                  :class="[
                    'timeline-marker',
                    item.image ? 'is-image is-32x32' : '',
                  ]"
                >
                  <img
                    v-if="item.image && item.image === '@/assets/logo-bf.jpeg'"
                    src="@/assets/logo-bf.jpeg"
                  />
                  <img
                    v-if="item.image && item.image === '@/assets/log-rp.png'"
                    src="@/assets/log-rp.png"
                  />
                </div>
                <div class="timeline-content">
                  <p class="heading" v-if="item.date">
                    <span>{{ item.date }}</span>
                  </p>
                  <p v-if="item.name" class="name">{{ item.name }}</p>
                  <p v-if="item.place" class="place">{{ item.place }}</p>
                  <p v-if="item.location" class="location">{{ item.location }}</p>
                  <p v-if="item.description" class="description">
                    {{ item.description }}
                  </p>
                  <p class="link" v-if="item.link">
                    <a :href="item.link" target="_blank">Link</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
export default {
  name: "Resume",
  data() {
    return {
      columns: 2,
      cv: [
        {
          image: "@/assets/logo-idf.svg",
          date: "2020/2021",
          name: "UX/UI Design Course",
          place: "Interaction Design Foundation",
          location: "London, UK",
          description: "Online UX design courses by experts, with industry-recognized certificates",
          link: "https://www.interaction-design.org/lavinia-lattanzio",
          type: "Education",
        },
        {
          image: "@/assets/logo-roma3.png",
          date: "2020",
          name:
            "Master Degree in Cognitive Sciences of Action and Communication",
          place: "University of Roma Tre",
          location: "Rome, Italy",
          description: "Graduates with a vote of 110/110 cum laude. The course aimed to focus on the study of cognitive processes, intended as the elaboration of to this master I built theoretical and methodological competencies made available by the disciplines that contribute to cognitive sciences, such as psychology, philosophy, neuroscience, anthropology, and linguistics, to dig into the multiple dominions of communication. I studied the genesis and state of research activities.",
          link: "https://www.uniroma3.it/",
          type: "Education",
        },
        {
          image: "@/assets/logo-roma3.png",
          date: "2016",
          name:
            "Bachelor in Sciences of Communication",
          place: "University of Roma Tre",
          location: "Rome, Italy",
          description: `Thanks to my studies I possess knowledges and capabilities of understanding most of the activities and phenomenons related to the various sectors of communications and I acquired the competences to communicate at the best about information, ideas, problems and solutions. I learned the necessary abilities to produce informative and comunicative script which occurs in the different productive sectors, cultural and editorial range.`,
          link: "https://www.uniroma3.it/",
          type: "Education",
        },
        {
          date: "2009 - 2013",
          name: "Scientific And Bilingual High School Diploma",
          place: "G.B.Morgagni High School",
          location: "Rome, Italy",
          type: "Education",
        },
        {
          image: "@/assets/logo-bf.jpeg",
          date: "May 2018 - Oct 2018",
          name: "Business Development",
          place: "Brand Finance",
          location: "London, UK",
          description: `Supporting the marketing team, helping either on everyday tasks such as managing the Enquiries Mailbox, update the CRM network, sourcing data and information throught desk researches, or giving assistance on proposals and projects delivery, lead generation planning and email marketing campaigns. I also assisted the communication team in performing translation from business english to italian and in keeping up-to date and performing consistency cheks of the league tables launched on the company website “Brandirectory.co.uk” through a CMS webite.`,
          link: "https://brandfinance.com/",
          type: "Experience",
        },
        {
          image: "@/assets/logo-bf.jpeg",
          date: "May 2018 - Oct 2018",
          name: "Business Development Internship",
          place: "Brand Finance",
          location: "London, UK",
          description: `Supporting and helping out within the marketing department with daily tasks like contact sourcing, CRM update, calls and desk researches etc., but also within either the analyst department assisting in sourcing data, or the communication department, performing translations of reports and press releases from business english to italian and consistency cheks of the league tables launched on the company website “Brandirectory.co.uk” through a CMS webite.`,
          link: "https://brandfinance.com/",
          type: "Experience",
        },
        {
          image: "@/assets/log-rp.png",
          date: "2017",
          name: "Direct Marketing Assistant",
          place: "Red Planet Marketing Limited",
          location: "London, UK",
          description: `Stage 2 Brand Ambassador for the “Live On Campaign of Royal British Legion”. From this experience I learnt the main sales techniques and how to manage with all kind of costumers.`,
          link: "http://www.redplanetmarketing.co.uk/",
          type: "Experience",
        },
      ],
    };
  },
  computed: {
    experience() {
      return this.cv.filter((item) => item.type === "Experience");
    },
    education() {
      return this.cv.filter((item) => item.type === "Education");
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  h3 {
    margin-bottom: 0;
  }
  .svg {
    width: 52px;
  }
}
.block {
  margin-bottom: 30px;
  text-align: left;
}
.photo img {
  padding: 12px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
}
.content {
  text-align: left;
}
.subtitle {
  position: relative;
  display: inline-block;
  font-size: 22px;
  text-transform: capitalize;
  font-weight: 700;
  color: #282828;
  margin-bottom: 35px;
  padding-bottom: 5px;
}
.timeline-content {
 p {
    margin-bottom: 0.5em!important;
  }
  .heading {
    span {
      background-color: $primary;
      border-radius: 3px;
      color: #fff;
      padding: 3px;
    }
  }
  .name {
    font-weight: 800;
  }
  .location {
    font-size: 0.8em;
}
  .description {
    font-size: 12px;
  }
  .link {
    font-size: 12px;
    a {
      color: $primary !important;
      text-decoration: underline;
    }
  }
}
</style>
<style lang="scss">
.svg {
  .Web {
    opacity: 1;
  }
  .Header {
    stroke-width: 2px;
    stroke: #e64980;
  }
}
.timeline-marker:not(.is-image) {
  background: #e64980 !important;
  border-color: #e64980 !important;
}
</style>
